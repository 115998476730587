@tailwind base;
@tailwind components;
@tailwind utilities;

/* import font here for blond and others*/
@import url('https://fonts.googleapis.com/css2?family=Tiny5&display=swap');

@layer base {
  h1 {
    @apply font-[Tiny5];

  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
  input {
    @apply border-b;
    @apply bg-form-rgba;
    @apply mb-4;
    @apply outline-none;
    @apply rounded-md;
    @apply p-2;
  }

  textarea {
    @apply border-black;
    @apply bg-form-rgba;
    @apply mb-4;
    @apply outline-none;
    @apply rounded-none;
    @apply resize-none;
    @apply rounded-xl;
    @apply p-2;
  }
}
